<template lang="pug">
	.container
		transition(name='fade' mode='out-in')
			.box-second-row.p-4( v-if="services.length >0" key='1')
				h4 {{title}}
				.row.mt-5
					.col-6.col-lg-3(v-for="service in services" :key="service._id")
						.card.cursor-pointer(@click='serviceClicked(service._id, service.shortName)')
							.card-body
								.icon
									img( class="service"
										:src="`${FILE_SERVER}/Services/Icon/`+service.icon.nameOnServer"
										:alt="service.name")
							.card-footer
								h4 {{service.name|| "" }}
			.card-loader(v-else key='2')
</template>

<script>
import { FILE_SERVER } from "@/helpers/general";
import "@/assets/loading.scss";
import api from "@/helpers/api";
export default {
  async mounted() {
    let slug = this.$route.params.slug;
    if (slug) {
      slug = slug.toUpperCase();
      this.services = await api.servicesByCategorySlug(slug);
      if (!this.services) this.$router.push("/");
      this.title = this.services[0].category.name;
    } else {
      this.$router.push("/");
    }
  },
  data() {
    return {
      services: [],
      title: "",
    };
  },
  methods: {
    serviceClicked(id, shortName) {
      let loc = "";
      switch (shortName) {
        case "WORLDLINK":
          loc = "/internet-worldlink";
          break;
        case "ARROWNET":
          loc = "/internet-arrownet";
          break;
        case "LIFENET":
          loc = "/internet-lifenet";
          break;
        case "FIRSTLINK":
          loc ="/internet-firstlink";
          break;
        case "COSMICNET":
          loc = "/internet-cosmicnet";
          break;
        case "SUBISU":
          loc = "/internet-subisu";
          break;
        case "BROADLINK":
          loc = "/internet-broadlink";
          break;
        case "VIANET":
          loc = "/internet-vianet";
          break;
        case "CLASSICTECH":
          loc = "/internet-classictech";
          break;
        case "NEA":
          loc = "/utility-nea";
          break;
        case "NWS":
          loc = "/utility-nws";
          break;
        case "DISHHOME":
          loc = "/utility-dishhome";
          break;
        case "MAXTV":
          loc = "/utility-maxtv";
          break;
        case "SIMTV":
          loc = "/utility-simtv"
          break;
        case "PRABHU_TV_OTT":
          loc = "/utility-prabhuTvOTT"
          break;
        case "PRABHU_TV_SETUP":
           loc = "/utility-prabhuTvSetUp"
          break;
        case "EDX":
          loc = "/edx-courses";
          break;
        case "BUS_SEWA":
          loc = "/bus-sewa";
          break;
        case "UBSW":
          loc = "/utility-upabhokta";
          break;
        case "GICINSURANCE":
          loc = "/insurance-gic";
          break;
        case "PREMIERINSURANCE":
          loc = "/insurance-premier";
          break;
        case "UNIONLIFEINSURANCE":
          loc = "/insurance-union";
          break;
        case "SAGARMATHAINSURANCE":
          loc = "/insurance-sagarmatha";
          break;
        case "RELIANCELIFEINSURANCE":
          loc = "/insurance-reliance"
          break;
        case "HIMALAYANINSURANCE":
          loc = "/insurance-himalayan";
          break;
        case "SIDDHARTHAINSURANCE":
          loc = "/insurance-siddhartha"
          break;
        case "NLGINSURANCE":
          loc = "/insurance-nlg";
          break;
        case "NTC_DATA_PACK":
          loc = "/data-pack/ntc";
          break;
        case "NCELL_DATA_PACK":
          loc = "/data-pack/ncell";
          break;
        case "DOMESTIC_FLIGHT":
          loc = "/flight/search";
          break;
        case "MANAKAMANACABLE":
          loc = "/cable_car/manakamanacablecar"
          break;
      }
      if (loc) {
        this.$router.push(loc);
      }
    },
  },
  computed: {
    FILE_SERVER() {
      return FILE_SERVER;
    },
  },
};
</script>
